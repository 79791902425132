/**
 * Utilities specific to MA
 */

/**
 * Utilities specific to CTCPAS
 */

import convertToLocalTime from '../../vendor/imarc/inkwell/resources/scripts/modules/datetimeHandler';

const timeElements = [...document.querySelectorAll('time')];

window.addEventListener('content-populated', () => {
    timeElements.forEach(convertToLocalTime);
});

/**
(function() {
    const head = document.getElementsByTagName('head').item(0);
    const script = document.createElement('script');
    const src =
        document.location.protocol == 'https:'
            ? 'https://www.formilla.com/scripts/feedback.js'
            : 'http://www.formilla.com/scripts/feedback.js';

    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', src);
    script.setAttribute('async', true);

    let complete = false;
    script.onload = script.onreadystatechange = function() {
        if (
            !complete &&
            (!this.readyState ||
                this.readyState == 'loaded' ||
                this.readyState == 'complete')
        ) {
            complete = true;
            Formilla.guid = 'cs46abc1-e4c1-4bc7-b5b2-48e11130e4ea';
            Formilla.loadWidgets();
        }
    };

    head.appendChild(script);
})();
**/
